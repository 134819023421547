<template>
  <van-popup teleport="body" v-model:show="show" round>
    <div class="wrapper" @click.stop>
      <div class="block">
        <div class="block-title">回传推广作品链接</div>
        <van-cell-group inset>
          <van-field
            class="block-msg"
            v-model="message"
            rows="5"
            autosize
            type="textarea"
            placeholder="请输入推广作品链接 例 https://"
          />
        </van-cell-group>
        <van-button
          type="primary"
          size="small"
          class="block-submit"
          @click="submit"
          >保存</van-button
        >
        <i class="font_family icon-cha block-close" @click="close"></i>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { setCompleteTask } from '@/api/extension.js'
import { Toast } from 'vant'
export default {
  name: 'addExtension',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: null
    }
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.message = this.options.promotion_url
      }
    }
  },
  data() {
    return {
      message: ''
    }
  },
  methods: {
    async submit() {
      if (this.message == '') {
        return Toast('请填写推广地址')
      }
      // 判断是否是链接
      let strRegexp = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/
      let REarr = this.message.match(strRegexp)
      if (!REarr) {
        return Toast('请输入正确的链接地址')
      }
      let legitimate = REarr.find(item => {
        if (strRegexp.test(item)) {
          if (this.options.link_type == '20') {
            let includesFlag = item
              .toLowerCase()
              .includes(this.options.works_info_link)
            if (!includesFlag) {
              Toast('请输入正确的链接地址')
              return false
            }
          }
          return item
        }
      })
      if (legitimate) {
        await setCompleteTask({
          taskId: this.options.id,
          promotionUrl: legitimate
        })
        Toast('提交成功')
        this.message = ''
        this.$emit('updateData', this.options.id)
        this.$emit('update:show', false)
      }
    },
    close() {
      this.message = ''
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  position: relative;
  width: 622px;
  border-radius: 18px;
  padding: 0px 18px;
  background-color: #fff;
  .block-title {
    font-size: 32px;
    text-align: center;
    font-weight: bold;
    margin: 30px 0px;
  }
  .block-msg {
    width: 100%;
    background: #f7f8fa;
  }
  .block-submit {
    margin: 40px 29px;
    width: 526px;
    background: #fe774c;
    color: white;
    font-size: 34px;
    border: none;
    border-radius: 6px;
  }
  .block-close {
    position: absolute;
    top: 38px;
    right: 36px;
    font-size: 28px;
  }
}
</style>
