<template>
  <zhihuNavbar @launchSearch="launchSearch" />
  <van-list ref='listDom' class="task-list" v-show="list != null && list.length > 0" v-model:loading="loading"
    :finished="finished" :immediate-check="false" finished-text="我也是有底线的呦~" @load="onLoad">
    <zhihuWord v-for="item in list" :key="item.id" type="2" @showAdd="showAdd" :data="item">
    </zhihuWord>
  </van-list>
  <van-empty class="empty" description="空空的~快去文案库看看吧" v-show="list != null && list.length == 0" />

  <!-- 申请关键词悬浮按钮 -->
  <div :class="['applyWordBtn',scrollOver?'':'applyWordBtnHide']" @click="applyWord">
    <div>申请</div>
    <div>关键词</div>
    <van-icon name="plus" />
  </div>
  <addExtensionAddress @updateData="updateData" v-model:show="show" v-model:options="options" />
  <Regulation ref="regulation"></Regulation>
  <!-- <jumpBinding ref="jumpBinding" /> -->
</template>
<script>
import { getZhihuKeyWords } from '@/api/extension.js'
import { getUserInfo } from '@/api/information.js'
// import jumpBinding from '@/components/jumpBinding.vue'
import addExtensionAddress from '../components/addExtensionAddress.vue'
import zhihuWord from '../components/zhihuWord.vue'
import zhihuNavbar from '../components/zhihuNavBar.vue'
import Regulation from '../components/regulation.vue'
export default {
  name: 'tealent',
  components: {
    zhihuWord,
    addExtensionAddress,
    zhihuNavbar,
    Regulation
    // jumpBinding
  },

  data() {
    return {
      user: {},
      // 上拉加载用
      loading: false,
      finished: false,
      page: {
        page: 1,
        limit: 20
      },
      // 10 待审核 20待回传 30 审核失败 40回传审核 50回传成功 60 回传失败
      list: [],
      show: false,
      options: {},
      searchInfo: {},
      timer: null,
      scrollTop: 0,
      scrollEndTop: 0,
      scrollOver: true
    }
  },
  async mounted() {
    this.getUserInfo()
    this.getZhihuKeyWordsFun(true)
    // 监听页面滚动和停止滚动
    let isScrollEnd = () => {
      this.scrollEndTop =
        document.documentElement.scrollTop || document.body.scrollTop
      if (this.scrollTop === this.scrollEndTop) {
        this.scrollOver = true
      }
    }
    window.addEventListener('scroll', e => {
      this.scrollOver = false
      clearTimeout(this.timer)
      this.timer = setTimeout(isScrollEnd, 800)
      this.scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
    })
  },
  methods: {
    // 请求任务列表
    async getZhihuKeyWordsFun(clearFlag = false) {
      if (clearFlag) {
        this.page.page = 1
      }
      let res = await getZhihuKeyWords(
        Object.assign({}, this.page, this.searchInfo)
      )
      // 从这里清是为了减少空白页面存在的时间
      if (clearFlag) {
        this.list = []
      }
      this.list.push(...res.list)
      this.loading = false
      if (this.list.length >= res.count) {
        this.finished = true
      } else {
        this.finished = false
        this.page.page += 1
      }
    },
    // 更新本条信息
    async updateData(taskId) {
      let res = await getZhihuKeyWords({ taskId })
      let newData = res.list[0]
      this.list = this.list.map(el => {
        if (el.id === newData.id) {
          el = newData
        }
        return el
      })
    },
    async getUserInfo() {
      this.user = await getUserInfo()
    },
    // 添加回传推广地址
    showAdd(data) {
      this.show = true
      this.options = data
    },
    launchSearch(val) {
      this.searchInfo = val
      this.getZhihuKeyWordsFun(true)
    },
    onLoad() {
      this.getZhihuKeyWordsFun()
    },
    // 申请关键词
    applyWord() {
      /* if (this.user.apply_settlement === 0) {
        return this.$refs.jumpBinding.showPopup()
      } */
      // this.$router.push('/applyWord')
      this.$refs.regulation.state = true
    }
  }
}
</script>

<style lang="less" scoped>
.task-list {
  padding: 10px 20px 100px;
}
.empty {
  padding-top: 280px;
}

.applyWordBtn {
  position: fixed;
  width: 100px;
  height: 100px;
  right: 5%;
  bottom: 20%;
  padding: 10px;
  background: #ff784c;
  color: white;
  font-size: 22px;
  border-radius: 50%;
  transition: all 0.3s ease;
  i {
    font-weight: bold;
  }
}
.applyWordBtnHide {
  transform: translateX(150%) rotate(180deg);
}
</style>
