<template>
  <NavBar @launchSearch="launchSearch" />
  <van-list class="task-list" v-show="list != null && list.length > 0"
    v-model:loading="loading" :finished="finished" :immediate-check="false"
    finished-text="我也是有底线的呦~" @load="onLoad">
    <extensionBook v-for="item in list" :key="item.id" type="2"
      @showAdd="showAdd" :data="item">
    </extensionBook>
  </van-list>
  <van-empty class="empty" description="空空的~快去文案库看看吧"
    v-show="list != null && list.length == 0" />
  <addExtensionAddress @updateData="updateData" v-model:show="show"
    v-model:options="options" />
</template>
<script>
import extensionBook from '@/components/extensionBook.vue'
import { gettaskList } from '@/api/extension.js'
import addExtensionAddress from '../components/addExtensionAddress.vue'
import NavBar from '../components/navBar.vue'
export default {
  name: 'tealent',
  components: {
    extensionBook,
    addExtensionAddress,
    NavBar
  },
  mounted() {
    this.getTaskListFun(true)
  },
  data() {
    return {
      // 上拉加载用
      loading: false,
      finished: false,
      page: {
        page: 1,
        limit: 20
      },
      list: [],
      show: false,
      options: {},
      searchInfo: {}
    }
  },
  methods: {
    // 请求任务列表
    async getTaskListFun(clearFlag = false) {
      if (clearFlag) {
        this.page.page = 1
      }
      let res = await gettaskList(Object.assign({}, this.page, this.searchInfo))
      // 从这里清是为了减少空白页面存在的时间
      if (clearFlag) {
        this.list = []
      }
      this.list.push(...res.list)
      this.loading = false
      if (this.list.length >= res.count) {
        this.finished = true
      } else {
        this.finished = false
        this.page.page += 1
      }
    },
    // 更新本条信息
    async updateData(taskId) {
      let res = await gettaskList({ taskId })
      let newData = res.list[0]
      this.list = this.list.map(el => {
        if (el.id === newData.id) {
          el = newData
        }
        return el
      })
    },
    // 添加回传推广地址
    showAdd(data) {
      this.show = true
      this.options = data
    },
    launchSearch(val) {
      this.searchInfo = val
      this.getTaskListFun(true)
    },
    onLoad() {
      this.getTaskListFun()
    }
  }
}
</script>

<style lang="less" scoped>
.task-list {
  padding: 10px 20px 100px;
}
.empty {
  padding-top: 280px;
}
</style>
