<template>
  <van-overlay :show="state" z-index="1001"  @click="state = false">
    <div class="overlay-wrap">
      <div class="wrap">
        <div class="content">
          <h4>平台升级通知</h4>
          <p class="line-1">海文达人平台已经完成升级改版，现用系统申请关键词功能已停用。</p>
          <p class="line-2">关于升级需要注意的有：</p>
          <ul>
            <li>1、现用系统和升级后的系统各自独立，数据不同步。</li>
            <li>2、现用系统申请通过的关键词有尾单产出会通过现用系统持续结算。</li>
            <li>3、升级后的系统需要重新注册。</li>
            <li>4、升级后的系统知乎订单单价升至8元/单，并支持番茄小说、番茄畅听、今日头条、抖音故事推文等项目。</li>
            <li>5、对于系统升级有疑问可以关注微信公众号【海文达人助手】，查看详细升级说明。</li>
          </ul>
          <p class="line-3">希望各位达人继续创造出更多收益，爆单爆单~~~</p>
        </div>
        <div class="btn-box">
          <!-- <span @click="applyWord">继续申请关键词</span>
          <i></i> -->
          <span @click="toWebsite">注册新系统</span>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  data() {
    return {
      state: false
    }
  },
  methods: {
    applyWord() {
      this.$router.push('/applyWord')
    },
    toWebsite() {
      window.location.href = 'https://www.haiwendaren.com'
    }
  }
}
</script>

<style lang="less" scoped>
.overlay-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.wrap {
  background-color: #fff;
  width: 85vw;
  border-radius: 10px;
  transform: translateY(-4vh);
  .content {
    padding: 20px;
  }
  h4 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  .line-1 {
    text-align: left;
    font-size: 24px;
    color: #666;
    margin-bottom: 20px;
  }
  .line-2 {
    color: #333;
    text-align: left;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .line-3 {
    color: #333;
    text-align: left;
    font-size: 24px;
    font-weight: bold;
  }
  ul {
    li {
      text-align: left;
      text-indent: 1em;
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
}
.btn-box {
  border-top: #ccc 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  span {
    flex: 1;
    font-size: 26px;
    font-weight: bold;
  }
  i {
    display: block;
    width: 1px;
    height: 100%;
    background-color: #ccc;
  }
}
</style>