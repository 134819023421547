<template>
  <div class="book">
    <div class="book-top">
      <div class="book-title text-ellipsis">{{ data.keywords }}</div>
      <div class="book-status">
        <div class="book-status-name" :style="{'color':color}">
          {{statusName(data)}}
        </div>
        <div class="book-status-edit" v-if="data.examineStatus==60"
          @click="addRess({keywordsId:data.keywordsId,type:'edit'})">
          编辑
        </div>
      </div>
    </div>
    <!--examineStatus==10||20||30  -->
    <div class="book-conent"
      v-if="data.examineStatus==10||data.examineStatus==20||data.examineStatus==30">
      <div class="book-copy text-ellipsis">
        <span class="book-copy-label"> 内容地址： </span>
        <div @click="onCopy(data.contentUrl)" style="color: #fd846f"
          class="promotion-link text-ellipsis">
          <i class="font_family icon-fuzhipeizhi" />
          {{ data.contentUrl}}
        </div>
      </div>
      <div class="book-copy text-ellipsis">
        <div class="bc-row-label">申请日期：</div>
        <div class="promotion-link text-ellipsis">
          {{ $utils.setDateFormat(data.createTime) }}
        </div>
      </div>
      <!--20 审核通过待回传 -->
      <div class="book-setUrl">
        <div @click="toBackList(data)">
          <Plus />推广链接
        </div>
      </div>
      <!-- <div class="book-setUrl" v-if="data.examineStatus==20"
        @click="addRess({keywordsId:data.keywordsId,type:'create',name:data.keywords})">
        请务必填写回传信息，方便统计数据做结算
      </div> -->
      <!-- 30失败 -->
      <div class="book-copy reason-error" v-if="data.examineStatus==30">
        <span class="book-copy-label"> 失败原因： </span>
        <div class="promotion-link">
          {{ data.examineRemark}}
        </div>
      </div>
    </div>
    <!--40回传审核 50回传成功 60 回传失败 -->
    <div class="book-conent"
      v-if="data.examineStatus==40||data.examineStatus==50||data.examineStatus==60">
      <div class="bc-row">
        <div class="bc-col">
          <div class="bc-row">
            <div class="bc-row-label">推广渠道：</div>
            <div class="bc-row-val text-ellipsis">{{ data.promotionName }}
            </div>
          </div>
        </div>
        <div class="bc-col">
          <div class="bc-row">
            <div class="bc-row-label">推广账号：</div>
            <div class="bc-row-val text-ellipsis">{{ data.platformName }}
            </div>
          </div>
        </div>
      </div>
      <div class="bc-row">
        <div class="bc-col">
          <div class="bc-row">
            <div class="bc-row-label">申请日期：</div>
            <div class="bc-row-val text-ellipsis">
              {{ $utils.setDateFormat(data.createTime) }}
            </div>
          </div>
        </div>
        <div class="bc-col">
          <div class="bc-row">
            <div class="bc-row-label">发布日期：</div>
            <div class="bc-row-val text-ellipsis">
              {{ data.promotionDate }}
            </div>
          </div>
        </div>
      </div>
      <div class="book-copy text-ellipsis">
        <span class="book-copy-label"> 内容地址： </span>
        <div @click="onCopy(data.contentUrl)" style="color: #fd846f"
          class="promotion-link text-ellipsis">
          <i class="font_family icon-fuzhipeizhi" />
          {{ data.contentUrl}}
        </div>
      </div>
      <div class="book-copy text-ellipsis">
        <span class="book-copy-label"> 作品链接： </span>
        <div @click="onCopy(data.promotionUrl)" style="color: #fd846f"
          class="promotion-link text-ellipsis">
          <i class="font_family icon-fuzhipeizhi" />
          {{ data.promotionUrl}}
        </div>
      </div>
      <div class="book-copy reason-error" v-if="data.examineStatus==60">
        <span class="book-copy-label"> 失败原因： </span>
        <div class="promotion-link">
          {{ data.examineRemark}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { zhihuStatusList } from '@/utils/publicVar'
import Plus from '@/assets/svg/plus.vue'
export default {
  name: 'book',
  components: {
    Plus
  },
  props: {
    data: {
      type: Object
    }
  },
  computed: {
    //examineStatus 10 待审核 20待回传 30 审核失败 40回传审核 50回传成功 60 回传失败
    color() {
      let s = this.data.examineStatus
      if (s == 10 || s == 20) {
        return 'rgb(152 152 152)'
      } else if (s == 30 || s == 60) {
        return 'rgb(253 77 77)'
      } else if (s == 40) {
        return 'rgb(243 217 71)'
      } else if (s == 50) {
        return 'rgb(85 195 253)'
      }
    }
  },
  data() {
    return {
      zhihuStatusList
    }
  },

  methods: {
    // 处理状态名称
    statusName(data) {
      const { examineStatus, promotionCount } = data
      let target = this.zhihuStatusList.find(item => {
        return item.id == examineStatus
      })
      if (examineStatus == 20 && promotionCount > 0) {
        return '已回传'
      }
      if (target) {
        return target.name
      }
      return '暂无'
    },
    toBackList(data) {
      this.$router.push({
        path: '/backList',
        query: { id: data.keywordsId, type: 'zh' }
      })
    },
    addRess(query) {
      this.$router.push({ path: '/editWord', query })
    },
    onCopy(text) {
      this.$copyText(text)
        .then(() => {
          Toast('已成功复制到剪切板')
        })
        .catch(() => {
          Toast('复制失败')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.book {
  background: white;
  padding: 30px 20px 24px;
  border-bottom: 1px solid rgb(241, 241, 241);
  .book-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .book-title {
      max-width: 70%;
      font-size: 30px;
      font-weight: bold;
    }
    .book-status {
      display: flex;
      align-items: top;
      font-size: 22px;
      .book-status-name {
        margin-right: 20px;
      }
      .book-status-edit {
        padding: 2px 14px;
        border-radius: 4px;
        color: white;
        background-image: linear-gradient(to right, #ff9f71, #fe764b);
      }
    }
  }
  .book-conent {
    .bc-row {
      display: flex;
      align-items: flex-end;
      font-size: 24px;
      .bc-col {
        width: 50%;
        padding-bottom: 15px;
      }
      .bc-row-label {
        color: #333;
        white-space: nowrap;
      }
      .bc-row-val {
        color: #999999;
      }
    }
    margin-top: 10px;
    .book-copy {
      display: flex;
      align-items: flex-end;
      font-size: 24px;
      text-align: left;
      padding-bottom: 16px;
      .book-copy-label {
        min-width: 130px;
      }
    }
    .reason-error {
      align-items: flex-start;
    }
    .book-setUrl {
      display: flex;
      justify-content: flex-end;
      div {
        width: 160px;
        padding: 8px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        font-weight: bold;
        color: #fd846f;
        border-radius: 12px;
        border: 2px solid #fd846f;
        svg {
          font-size: 30px;
        }
      }
    }
    .icon-fuzhipeizhi {
      font-size: 24px;
    }
  }
}
</style>
