<template>
  <van-sticky>
    <div class="searchB">
      <van-search v-model="value" show-action placeholder="请输入搜索关键词" clearable
        @focus="hiddenTabBar(false)" @blur="hiddenTabBar(true)"
        @search="onSearch">
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
      <!-- 我的推广extension使用的下拉菜单 -->
      <div class="selectBox">
        <div class="sb-platform text-ellipsis"
          @click="selectCondition(channel, '推广渠道')">
          <span v-show="channelVal.id == 0">推广渠道</span>
          <span v-show="channelVal.id != 0"> {{ channelVal.name }}</span>
          <van-icon name="arrow-down" />
        </div>
        <div class="sb-platform text-ellipsis"
          @click="selectCondition(platform, '推广产品')">
          <span v-show="platformVal.id == 0">推广产品</span>
          <span v-show="platformVal.id != 0">{{ platformVal.name }}</span>
          <van-icon name="arrow-down" />
        </div>
        <div class="sb-platform text-ellipsis"
          @click="selectCondition(bookList, '书名')">
          <span v-show="bookListVal.id == 0">书名</span>
          <span v-show="bookListVal.id != 0"> {{ bookListVal.name }}</span>
          <van-icon name="arrow-down" />
        </div>
      </div>
    </div>
  </van-sticky>
  <van-popup v-model:show="show" round position="bottom">
    <van-picker ref="picker" :title="title" value-key="name" :columns="columns"
      @cancel="clearPicker" @confirm="onSelect" />
  </van-popup>
</template>

<script>
import {
  getTaskPlatform,
  getPromotionPlatformFilter,
  getUserBookList
} from '@/api/public.js'
export default {
  emits: ['launchSearch'],
  data() {
    return {
      show: false,
      value: '',
      columns: [],
      title: '',
      //↓ task使用
      platform: [],
      platformVal: {},
      sex: [],
      sexVal: {},
      category: [],
      categoryVal: {},
      //
      channel: [],
      channelVal: {},
      bookList: [],
      bookListVal: {},
      styleList: [],
      styleVal: {},
      sortType: '1',
      useStatus: 0,
      extensionFlag: false
    }
  },
  async mounted() {
    this.getExtensionCondition()
    this.getTaskPlatform()
    this.getUserBookList()
  },
  methods: {
    //获取推广产品列表
    async getTaskPlatform() {
      this.platform = await getTaskPlatform()
      this.platformVal = this.platform[0]
    },
    // 我的推广推广渠道筛选列表
    async getExtensionCondition() {
      let res = await getPromotionPlatformFilter()
      let channel = res.map(item => {
        return {
          name: item.name,
          id: item.id
        }
      })
      channel.unshift({
        name: '全部',
        id: 0
      })
      this.channel = channel
      this.channelVal = this.channel[0]
    },
    // 获取用户推广的书籍
    async getUserBookList() {
      let res = await getUserBookList({
        appPlatform: this.platformVal.id,
        promotionPlatformId: this.channelVal.id
      })
      const arr = Object.entries(res).map(([_key, value]) => value)
      arr.unshift({ book_name: '全部', book_id: 0 })
      this.bookList = arr.map(item => {
        return {
          name: item.book_name,
          id: item.book_id
        }
      })
      this.bookListVal = this.bookList[0]
    },
    // 筛选条件
    selectCondition(data, title) {
      this.columns = data
      this.title = title
      this.show = true
    },
    onSearch() {
      /*    this.$store.dispatch('setSearchVal', {
        searchKeywords: this.value,
        platform: this.platformVal.id,
        promotionPlatformId: this.channelVal.id,
        bookId: this.bookListVal.id
      }) */
      this.$emit('launchSearch', {
        searchKeywords: this.value,
        platform: this.platformVal.id,
        promotionPlatformId: this.channelVal.id,
        bookId: this.bookListVal.id
      })
    },
    clearPicker() {
      this.$refs.picker.setColumnIndex(0)
      this.show = false
    },
    onSelect(item) {
      if (this.title == '推广产品') {
        this.platformVal = item
        this.bookListVal = {}
        this.getUserBookList()
      } else if (this.title == '推广渠道') {
        this.channelVal = item
        this.bookListVal = {}
        this.getUserBookList()
      } else if (this.title == '书名') {
        this.bookListVal = item
      }
      this.show = false
      this.onSearch()
    },
    hiddenTabBar(flag) {
      /*     let MaxH = window.innerHeight
      var setHide = e => {
        let eH = e.currentTarget.innerHeight
        if (eH < MaxH) {
          this.$store.dispatch('setTabBarDisplay', false)
        } else {
          this.$store.dispatch('setTabBarDisplay', true)
        }
      }
      if (!flag) {
        window.addEventListener('resize', setHide)
      } else {
        window.removeEventListener('resize', setHide)
      } */
    }
  }
}
</script>

<style scope lang="less">
.searchB {
  width: 100%;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* task使用 */
  .selectBox-task {
    padding: 0px 25px 20px;
    color: #666666 !important;
    font-size: 22px;
    .s-task-top {
      display: flex;
      align-items: center;
      justify-content: start;
      .sb-platform {
        max-width: 250px;
        min-width: 145px;
        border: 1px solid #999999;
        padding: 8px 10px;
        border-radius: 40px;
        margin-right: 10px;
      }
    }
    .selectBox-sort {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 8px;
      flex-wrap: wrap;
      .selectBox-sort-btn {
        color: #999999;
        border-radius: 40px;
        background: #f3f3f3;
        padding: 8px 25px;
        margin-top: 10px;
        margin-right: 10px;
      }
      .sortActive {
        color: #ff784c !important;
        background: none !important;
        border: 1px solid #ff784c !important;
      }
    }
  }
  /* extension使用 */

  .selectBox {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0px 25px 20px;
    color: #666666 !important;
    font-size: 22px;
    .sb-platform {
      max-width: 250px;
      min-width: 145px;
      border: 1px solid #999999;
      border-radius: 40px;
      padding: 8px 10px;
      margin-right: 10px;
    }
  }
}
.van-search {
  padding: 20px;
}
.van-search__content {
  border-radius: 50px;
}
</style>
