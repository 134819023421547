export let zhihuStatusList = [
  {
    id: 0,
    name: '全部'
  },
  {
    id: 10,
    name: '待审核'
  },
  {
    id: 20,
    name: '待回传'
  },
  {
    id: 30,
    name: '审核失败'
  },
  {
    id: 40,
    name: '回传审核'
  },
  {
    id: 50,
    name: '回传成功'
  },
  {
    id: 60,
    name: '回传失败'
  }
]
