<template>
  <van-sticky>
    <div class="searchB">
      <van-search v-model="value" show-action placeholder="请输入搜索关键词" clearable
        @search="onSearch">
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
      <!-- 我的推广extension使用的下拉菜单 -->
      <div class="selectBox">
        <div class="sb-platform text-ellipsis"
          @click="selectCondition(zhihuStatusList, '关键词状态')">
          <span v-if="statusVal.id == 0">关键词状态</span>
          <span v-else>{{ statusVal.name }}</span>
          <van-icon name="arrow-down" />
        </div>
        <div class="sb-platform text-ellipsis"
          @click="selectCondition(channel, '媒体渠道')">
          <span v-if="channelVal.length <2||channelVal[0].id==0">平台/账号</span>
          <span v-else>
            {{ channelVal[0].name+'/'+channelVal[1].name}}</span>
          <van-icon name="arrow-down" />
        </div>

      </div>
    </div>
  </van-sticky>
  <van-popup v-model:show="show" round position="bottom">
    <van-picker ref="picker" :title="title" value-key="name" :columns="columns"
      @cancel="clearPicker" @confirm="onSelect" />
  </van-popup>
</template>

<script>
import { zhihuStatusList } from '@/utils/publicVar'
import { getPromotionPlatform } from '@/api/public'

export default {
  emits: ['launchSearch'],
  data() {
    return {
      zhihuStatusList,
      show: false,
      value: '',
      columns: [],
      title: '',
      channel: [],
      channelVal: [
        { id: 0, name: '平台' },
        { id: 0, name: '账号' }
      ],
      statusVal: {}
    }
  },
  async mounted() {
    this.getPromotionPlatform()
    this.statusVal = this.zhihuStatusList[0]
  },
  methods: {
    // 平台及账号
    async getPromotionPlatform() {
      let res = await getPromotionPlatform({ isZhihu: 20 })
      let channel = res.map(item => {
        // 处理平台没有账号的情况
        let children = item.kocPromotion.map(promotion => {
          return {
            name: promotion.platform_code,
            id: promotion.id
          }
        })
        children.unshift({ id: 0, name: '全部' })
        return {
          name: item.zhihuName,
          id: item.id,
          children
        }
      })
      channel.unshift({
        name: '全部',
        id: 0,
        children: [{ id: 0, name: '全部' }]
      })
      this.channel = channel
    },

    // 筛选条件
    selectCondition(data, title) {
      this.columns = data
      this.title = title
      this.show = true
    },
    onSearch() {
      this.$emit('launchSearch', {
        keywords: this.value,
        examineStatus: this.statusVal.id,
        promotionPlatformId: this.channelVal[0].id,
        kocPlatformId: this.channelVal[1].id
      })
    },
    clearPicker() {
      this.$refs.picker.setColumnIndex(0)
      this.show = false
    },
    onSelect(val) {
      if (this.title == '关键词状态') {
        this.statusVal = val
        this.show = false
        this.onSearch()
      } else if (this.title == '媒体渠道') {
        if (val.length == 2) {
          this.channelVal = val
          this.show = false
          this.onSearch()
        }
      }
    }
  }
}
</script>

<style scope lang="less">
.searchB {
  width: 100%;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* extension使用 */

  .selectBox {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0px 25px 20px;
    color: #666666 !important;
    font-size: 22px;
    .sb-platform {
      max-width: 300px;
      min-width: 145px;
      border: 1px solid #999999;
      border-radius: 40px;
      padding: 8px 10px;
      margin-right: 10px;
    }
  }
}
.van-search {
  padding: 20px;
}
.van-search__content {
  border-radius: 50px;
}
</style>
