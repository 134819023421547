<template>
  <div class="book">
    <div class="book-top">
      <div class="book-title text-ellipsis">{{ data.book_name }}</div>
      <router-link @click.stop :to="{
          path: '/scriptExtension',
          query: { id: data.book_id, appName: data.app_name }
        }" outer-link class="book-link">使用文案
        <van-icon name="arrow" />
      </router-link>
    </div>
    <div class="book-content">
      <div class="bc-col">
        <div class="bc-row">
          <div class="bc-row-label">推广渠道：</div>
          <div class="bc-row-val text-ellipsis">{{ data.promotion_name }}</div>
        </div>
      </div>
      <div class="bc-col">
        <div class="bc-row">
          <div class="bc-row-label">推广产品：</div>
          <div class="bc-row-val text-ellipsis">
            {{ $utils.platform_type_filter(data.platform_type) }}
          </div>
        </div>
      </div>
      <div class="bc-col">
        <div class="bc-row">
          <div class="bc-row-label">应用名称：</div>

          <div @click.stop="onCopy(data.app_name)"
            class="bc-row-val text-ellipsis book-copy-text">
            <i class="font_family icon-fuzhipeizhi"></i>
            {{ data.app_name }}
          </div>
        </div>
      </div>
      <div class="bc-col">
        <div class="bc-row">
          <div class="bc-row-label">推广时间：</div>
          <div class="bc-row-val text-ellipsis">
            {{ $utils.setDateFormat(data.create_time) }}
          </div>
        </div>
      </div>
    </div>
    <div class="book-copy">
      <span class="book-copy-label"> 回复关键字： </span>
      <div @click.stop="onCopy(copyText)" class="book-copy-text">
        <i class="font_family icon-fuzhipeizhi"></i> {{ copyText }}
      </div>
    </div>
    <!-- <div v-show="data.promotion_url != null" class="book-copy text-ellipsis">
      <span class="book-copy-label"> 推广链接： </span>
      <div @click="addRess(data)" style="color: #fd846f"
        class="promotion-link text-ellipsis">
        {{ data.promotion_url }}
      </div>
    </div> -->
    <div class="book-setUrl">
      <div @click="toBackList(data)">
        <Plus />推广链接
      </div>
    </div>
    
    <!-- <div class="book-copy" style="align-items: center"
      v-if="data.promotion_url == null">
      <div class="book-setUrl" @click="addRess(data)">
        回传作品链接后系统开始统计数据，例：https://
      </div>
    </div> -->
  </div>
</template>

<script>
import { Toast } from 'vant'
import Plus from '@/assets/svg/plus.vue'

export default {
  name: 'book',
  components: {
    Plus
  },
  props: {
    data: {
      type: Object
    }
  },
  computed: {
    copyText() {
      return this.data.reply_keywords
    }
  },
  data() {
    return {}
  },
  methods: {
    toBackList(data) {
      this.$router.push({
        path: '/backList',
        query: { id: data.id, type: 'dr' }
      })
    },
    onCopy(text) {
      this.$copyText(text)
        .then(() => {
          Toast('已成功复制到剪切板')
        })
        .catch(() => {
          Toast('复制失败')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.book {
  background: white;
  padding: 30px 20px 24px;
  border-bottom: 1px solid rgb(241, 241, 241);
  .book-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .book-title {
      font-size: 30px;
      font-weight: bold;
    }
    .book-link {
      white-space: nowrap;
      font-size: 22px;
      padding-left: 40px;
      color: #fd846f;
    }
  }
  .book-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
    .bc-col {
      width: 50%;
      padding-bottom: 15px;
      .bc-row {
        display: flex;
        align-items: center;
        font-size: 24px;
        .bc-row-label {
          color: #333;
          white-space: nowrap;
        }
        .bc-row-val {
          color: #999999;
        }
      }
    }
  }
  .book-copy {
    display: flex;
    align-items: flex-end;
    font-size: 24px;
    padding-bottom: 16px;
    .book-copy-label {
      min-width: 130px;
      text-align: left;
    }
  }
  .promotion-link {
    width: 100%;
    font-size: 26px;
    text-align: left;
  }
  .book-copy-text {
    text-align: left;
    word-break: normal;
    color: rgb(253, 132, 111) !important;
  }
  .book-setUrl {
    display: flex;
    justify-content: flex-end;
    div {
      width: 160px;
      padding: 8px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      color: #fd846f;
      border-radius: 12px;
      border: 2px solid #fd846f;
      svg {
        font-size: 30px;
      }
    }
  }
  .icon-fuzhipeizhi {
    font-size: 24px;
  }
}
</style>
