<template>
  <van-tabs v-model:active="activeName" sticky color='#FE774C'
    title-active-color='#FE774C'>
    <!-- <van-tab title="达人推广" name="talent">
      <talent ref="talent" />
    </van-tab> -->
    <van-tab title="知乎推广" name="zhihu">
      <zhihu ref="zhihu" v-if="activeName=='zhihu'" />
    </van-tab>
  </van-tabs>
  <TabBar />
</template>

<script>
import TabBar from '@/components/tabBar.vue'
import talent from './childPage/talent.vue'
import zhihu from './childPage/zhihu.vue'
export default {
  name: 'extension',
  components: {
    talent,
    zhihu,
    TabBar
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例,将值传入fromPath
      if (
        from.name == 'applyWord' ||
        from.name == 'editWord' ||
        from.name == 'withdrawal'
      ) {
        vm.activeName = 'zhihu'
        vm.$nextTick(() => {
          if (vm.$refs.zhihu) {
            vm.$refs.zhihu.getZhihuKeyWordsFun(true)
          }
        })
      }
      // 判断上个路由来从新请求达人推广数据
      if (from.name == 'scriptExtension' || from.name == 'task') {
        vm.$nextTick(() => {
          if (vm.$refs.talent) {
            vm.$refs.talent.getTaskListFun(true)
          }
        })
      }
    })
  },
  mounted() {
    this.$store.commit('add', 'extension')
  },
  data() {
    return {
      activeName: 'zhihu'
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
</style>